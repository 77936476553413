import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Knox({ styles, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("form_header").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        className="formkit-content-background"
        style={{
          backgroundColor: styles.forElement("background_image").backgroundColor
        }}
      >
        <div
          className="formkit-background-image"
          name="background_image"
          style={styles.forElement("background_image")}
        />
        <div className="formkit-flex">
          <div className="formkit-main">
            <Elements.Content
              className="formkit-preheader"
              name="preheader"
              defaults={{
                content: "<p>Free webinar</p>"
              }}
            />
            <Elements.Heading
              className="formkit-header"
              name="header"
              defaults={{
                content:
                  "Writing clear, compelling narratives for documentary video."
              }}
            />
            <Elements.Region
              className="formkit-content"
              name="content"
              group="content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "<p>Learn the exact process that award-winning independent filmmaker, Ryan McKinney, follows to create his documentaries. You will walk away with a step-by-step system for crafting clear, compelling narratives for documentary video that will powerfully tell any story.</p>"
                }}
              />
            </Elements.Region>
            <div
              className="formkit-date"
              name="date_border"
              style={styles.forElement("border")}
            >
              <div style={styles.forElement("accent")}>
                <Elements.Content
                  className="formkit-date-header"
                  name="date_header"
                  defaults={{
                    content: "<p>Live Webinar</p>"
                  }}
                />
                <Elements.Event
                  group="date"
                  name="date"
                  className="formkit-date-time"
                  style={styles.forElement("date")}
                />
              </div>
            </div>
            <Elements.Region
              name="content_2"
              className="formkit-content"
              group="content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "<p><strong>Includes:</strong></p><ul><li>Free narrative framework download</li><li>Free documentary storytelling checklist</li><li>30 minute Q&A with Ryan</li><li>Earlybird access to Stories That Speak, the documentary storytelling online course</li></ul>"
                }}
              />
            </Elements.Region>
            <div className="formkit-authors">
              <div>
                <Elements.Image
                  name="bio_image"
                  defaults={{
                    src:
                      "https://images.unsplash.com/photo-1514346022778-231be2e1686b?ixlib=rb-1.2.1&q=65&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjExODA0N30&utm_source=convertkit&utm_medium=referral&utm_campaign=api-credit?fit=max&w=400"
                  }}
                  size={{ w: 300, h: 300 }}
                />
                <Elements.Content
                  className="formkit-image-header"
                  group="bio_header"
                  name="bio_header"
                  defaults={{
                    content: "<p>Taught by Ryan McKinney</p>"
                  }}
                />
                <Elements.Region
                  className="formkit-image-content"
                  name="bio_content"
                  group="bio_content"
                >
                  <Elements.Content
                    defaults={{
                      content:
                        "<p>Award-winning independent filmmaker from Portland, Oregan who has worked with National Geographic, the History Channel, and Discovery as a videographer and assistant director on several documentary projects as well as releasing his own documentary shorts.</p>"
                    }}
                  />
                </Elements.Region>
              </div>
              <div>
                <Elements.Image name="bio_image_2" size={{ w: 300, h: 300 }} />
                <Elements.Content
                  className="formkit-image-header"
                  group="bio_header"
                  name="bio_header_2"
                  defaults={{
                    content: "<p>Jess Smith</p>"
                  }}
                />
                <Elements.Region
                  className="formkit-image-content"
                  name="bio_content_2"
                  group="bio_content"
                >
                  <Elements.Content
                    defaults={{
                      content:
                        "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a aliquet justo. Suspendisse massa purus, egestas ut rutrum in, interdum vel nulla.</p>"
                    }}
                  />
                </Elements.Region>
              </div>
            </div>
          </div>
          <div className="formkit-form-main">
            <div
              className="formkit-form-content"
              style={styles.forElement("form_background")}
            >
              <Elements.Image
                className="formkit-form-image"
                name="image"
                defaults={{
                  src:
                    "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/gdQpZW9BTmi1fgqmmn39o8"
                }}
                size={{ w: 450, h: 250 }}
              />
              <div className="formkit-form-content-container">
                <Elements.Heading
                  className="formkit-form-header"
                  name="form_header"
                  tag="h3"
                  defaults={{
                    content: "Save your spot!"
                  }}
                />
                <Elements.Form>
                  <Elements.Errors />
                  <Elements.CustomFields
                    style={{
                      color: checkboxes_color
                    }}
                  >
                    <Elements.AddFieldButton />
                    <Elements.Button
                      name="submit"
                      group="button"
                      defaults={{ content: "Sign up today" }}
                    />
                  </Elements.CustomFields>
                </Elements.Form>
                <Elements.Content
                  className="formkit-disclaimer"
                  name="disclaimer"
                  defaults={{
                    content:
                      "<p>We respect your privacy. Unsubscribe at any time.</p>"
                  }}
                />
              </div>
            </div>
            <Elements.BuiltWith
              background="background_image"
              image="background_image"
            />
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Knox, { name: "Knox" })
